import getNewRelicAgent from 'helpers/newRelic/getNewRelicAgent';

const noticeError = async (err) => {
  if (err) {
    const newRelicAgent = await getNewRelicAgent();

    if (newRelicAgent) {
      newRelicAgent.noticeError(err);
    }
  }
};

export default noticeError;
