import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typography, Button } from '@tkww/the-bash-frontend';

import Image from 'components/Image';
import { SectionContainer } from 'components/grid';
import PageView from 'components/PageView';

export const DETAILS_BY_STATUS_CODE = {
  404: {
    image: '/images-next/errors/404-not-found.svg',
    imageAlt: '404 page not found',
    title: 'Uh oh…This page no longer exists.',
    subtitle: 'Head back to our homepage and get back to the party!',
  },
  500: {
    image: '/images-next/errors/500-server-error.svg',
    imageAlt: '500 server error',
    title: 'Oops! This is unexpected…',
    subtitle:
      'An error has occurred on this page. Head back to our homepage to get back to the party!',
  },
};

const ErrorPage = (props) => {
  const { statusCode, pageName } = props;
  const details = DETAILS_BY_STATUS_CODE[statusCode] || DETAILS_BY_STATUS_CODE[500];

  return (
    <PageView pageName={pageName}>
      <SectionContainer
        sx={(theme) => ({
          overflow: 'hidden',
          backgroundImage: 'url(/images-next/errors/background-mobile.svg)',
          backgroundPositionX: 'center',
          backgroundPositionY: -15,
          [theme.breakpoints.up('md')]: {
            backgroundImage: 'url(/images-next/errors/background-desktop.svg)',
            backgroundPositionX: 'calc(50% - 77px)',
            backgroundPositionY: -6,
          },
        })}
      >
        <Box height="100%" width="100%" pt="119px" pb={{ xs: 8.5, md: 13.25 }}>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.background.default,
              margin: '0 auto',
              textAlign: 'center',
              padding: '63px 24px 56px',
              border: '1px solid #BDBDBD',
              maxWidth: 562,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              [theme.breakpoints.up('md')]: {
                padding: '63px 40px 56px',
              },
            })}
          >
            <Box mb={4.25}>
              <Image src={details.image} alt={details.imageAlt} />
            </Box>
            <Typography variant="h1" mb={2} mt={0}>
              {details.title}
            </Typography>
            <Typography variant="body1" mb={1}>
              {details.subtitle}
            </Typography>
            {statusCode && (
              <Typography variant="body1" mb={3}>
                Error: {statusCode}
              </Typography>
            )}
            <Button data-testid="error-button" path="/" sx={{ width: 174, height: 54 }}>
              Home
            </Button>
          </Box>
        </Box>
      </SectionContainer>
    </PageView>
  );
};

ErrorPage.propTypes = {
  statusCode: PropTypes.number.isRequired,
};

export default ErrorPage;
