import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import ErrorPage from 'views/ErrorPage';
import noticeError from 'helpers/newRelic/noticeError';
import configureStore from 'state/configureStore';
import getServerSidePropsWrapper from 'helpers/getServerSidePropsWrapper';

export const Error = ({ statusCode, pageName }) => (
  <>
    <Head>
      <title>The Bash - {statusCode === 404 ? 'Page Not Found' : 'Internal Server Error'}</title>
    </Head>
    <ErrorPage statusCode={statusCode} pageName={pageName} />
  </>
);

export const handleServerSideProps = async (ctx) => {
  const { res, err } = ctx;
  await noticeError(err);

  if (res) {
    const { statusCode } = res;
    return { statusCode, pageName: `${statusCode} Error` };
  }

  return {};
};

export const getServerSideProps = configureStore.getServerSideProps(
  (store) => async (ctx) =>
    await getServerSidePropsWrapper({ ...ctx, store }, handleServerSideProps)
);

Error.propTypes = {
  statusCode: PropTypes.number.isRequired,
};

export default Error;
